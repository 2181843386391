/**
 * Covid19 page
 */
/* Import section */
import React from "react"
import { graphql } from "gatsby"
import Accordionfaq from "../components/accordionfaq"
import Bannerwithcta from "../components/bannerwithcta"
import Blocktopinfo from "../components/blocktopinfo"
import Titledecorative from "../components/titledecorative"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/pages/covid19.scss"


/* Page function declaration */
const Covidpage = ({data}) => {

  //Const data ACF page
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageCovid19

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroCovid.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroCovid})
  ))

  //Array for FAQ Covid
  let arrItemscovid = []
  dataAcf.repeaterDetailsCovid.map( (detail, index)  => (
    arrItemscovid.push({index: index, title: detail.titleDetailCovid, text: detail.descDetailCovid})
  ))

   //Const data Options
   const dataOptionsbanner = data.options.acfPageOptions
   var arrOptionsbanner = []
   arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
     return element.topicBannerCta === "covid"
   })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-covid top-section">
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroCovid} arrIntro={arrTextHero} colorBar="blue" />
      </section>

      {/*covid details section */}
      <section className="section-details-covid">
        <div className="wrap-intro-list wrap-maincontent">
          <Titledecorative title={dataAcf.titleDetailsCovid} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textDetailsCovid}}/>
          </div>
        </div>
        <div className="wrap-list-covid">
          <Blocktopinfo
            typeTopInfo="News"
            titleTopInfo={data.wpPost.title}
            ctaLabelTopInfo="Read Article"
            ctaLinkTopInfo={`/news/${data.wpPost.slug}`}
          />
          <div className="wrap-maincontent">
            <Accordionfaq arrItems={arrItemscovid} />
          </div>
        </div>
      </section>

      {/* Banner CTA Suppot */}
      <Bannerwithcta
        classBanner="section-banner-covid"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "coronavirus-covid-19"}) {
      acfPageCovid19 {
        textDetailsCovid
        titleDetailsCovid
        titleHeroCovid
        repeaterDetailsCovid {
          descDetailCovid
          titleDetailCovid
        }
        repeaterHeroCovid {
          introHeroCovid
        }
      }
      title
      seo {
        title
        metaDesc
      }
    }
    wpPost(databaseId: { eq: 11657 }) {
      databaseId
      title
      slug
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
/* Export page informations */
export default Covidpage
